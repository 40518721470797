import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import ImageSlider from '../components/ImageSlider';
import ModularBlocks from '../components/ModularBlocks';
import ContactFormInformation from '../components/ContactFormInformation';

const ContactPage = () => {
  const {
    datoCmsContact: {
      seoMetaTags,
      title,
      bannerText,
      imageSlider,
      modularBlocks,
      contactFormHeading,
      contactFormText,
      contactFormImage,
    },
  } = useStaticQuery(graphql`
    query ContactPageQuery {
      datoCmsContact {
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
        title
        bannerText
        imageSlider {
          ...ImageSliderImagesFragment
        }
        modularBlocks {
          ...ContentCardsModularBlockFragment
          ...ContentModularBlockFragment
          ...FeaturedInformationCardsModularBlockFragment
          ...FullWidthImageModularBlockFragment
          ...ImageContentCardsModularBlockFragment
          ...TestimonialsModularBlockFragment
        }
        contactFormHeading
        contactFormText
        contactFormImage {
          ...ContactFormInformationImageFragment
        }
      }
    }
  `);

  return (
    <Layout altThemeHeader={true}>
      <HelmetDatoCms seo={seoMetaTags} />
      <main>
        <Banner
          overline="Contact"
          heading={title}
          text={bannerText}
          altTheme={true}
          isContactPage={true}
        />
        <ImageSlider items={imageSlider} isContactPage={true} />
        <ModularBlocks items={modularBlocks} />
        <ContactFormInformation
          id="contact-info"
          heading={contactFormHeading}
          text={contactFormText}
          image={contactFormImage}
        />
      </main>
    </Layout>
  );
};

export default ContactPage;
